import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmailForm from "src/components/EmailForm";
import { DONATE_URL, VOLUNTEER_FORM } from "src/constants";
import Button from "@mui/material/Button";
import * as React from "react";
import { Helmet } from "react-helmet";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import ImageNavList from "src/components/ImageNavList";
import Layout from "src/components/Layout";
import Paragraph from "src/components/Paragraph";
import SubHeader from "src/components/SubHeader";
import img from "src/images/donate/donate-bg.jpg";
import keyboardImg from "src/images/donate/keyboard.jpg";

export default function DonatePage() {
  return (
    <Layout>
      <Helmet>
        <title>Donate</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle="Donate"
        title="Support Our Work"
        img={img}
        maxTitleWidth={"12em"}
      />
      <Container>
        <SubHeader>
          Support individuals and families through transformation
        </SubHeader>
        <Paragraph>
          East Oakland Community Project’s daily work and proven impact
          supporting people experiencing homelessness is made possible through
          investment from our community of donors.
        </Paragraph>
        <Paragraph>
          Every size donation is valuable and impactful — especially as we
          continue to provide specialized guidance, assistance, and care
          throughout the ongoing COVID-19 pandemic.{" "}
          <b>Will you donate to sustain our critical work?</b>
        </Paragraph>
        <Paragraph>
          <Button href={DONATE_URL} variant="contained" size="large">
            Donate now
          </Button>
        </Paragraph>
        <Paragraph>
          <b>Want to make a sustained impact?</b> Consider becoming a monthly
          pledger! By making a regular commitment, you will help our team
          deliver long-term, reliable, and compassionate care. Transforming the
          lives of individuals and families experiencing homelessness can take
          time — and <b>your consistent support makes a difference.</b>
        </Paragraph>
        <Paragraph>
          For any questions or to discuss the following additional ways to give
          to EOCP, please contact 510-746-3602.
        </Paragraph>
        <Typography component="ul" mb={3}>
          <li>
            In-kind gifts (toiletries, clean clothing and linens, reading
            material, children’s supplies, technology)
          </li>
          <li>Hosting a fundraiser</li>
          <li>Corporate matching gifts</li>
          <li>Securities, stocks, mutual funds, bonds</li>
          <li>
            Planned giving and bequests from your estate, IRA, or life insurance
            plan
          </li>
        </Typography>

        <Box
          component="img"
          src={keyboardImg}
          sx={{ width: "50%", float: "left" }}
          mr={2}
          mb={2}
        />

        <SubHeader id="volunteer">Stay Involved with EOCP</SubHeader>
        <Paragraph>
          There are a number of ways to support our day-to-day work at EOCP
          beyond a donation — we value the gift of your time, and welcome
          volunteers. Due to the COVID-19 pandemic and its impacts on our staff
          and protocols, our ability to onboard new volunteers is limited.
          However, we’d love to learn about your interest in volunteering!
          Please complete our interest form, and we will contact you as soon as
          opportunities are available.
        </Paragraph>
        <Paragraph>
          <Button variant="contained" href={VOLUNTEER_FORM} component="a">
            Volunteer Interest Form
          </Button>
        </Paragraph>
        <Box sx={{ clear: "both" }} />
        <Paragraph>
          Keep in touch! We send our most important updates through our email
          newsletter, and are increasingly going digital-only with our
          communications.
        </Paragraph>
        <Box p={3} sx={{ backgroundColor: "green.50" }}>
          <SubHeader>
            Sign up for our email newsletter to stay in the loop with our work!
          </SubHeader>
          <EmailForm hideTitle />
        </Box>
        <Box pt={4}>
          <ImageNavList
            links={[
              "/why-we-do-this-work",
              "/residential-services",
              "/community-based-services",
            ]}
          />
        </Box>
      </Container>
    </Layout>
  );
}
